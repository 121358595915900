import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../text.module.scss';

const Heading = ({
  level, children, className, color, weight, align, font, ...rest
}) => {
  const Element = `h${level < 1 ? 1 : level}`;
  const headingClassName = classNames(
    styles[`heading-${level}`], styles[color],
    styles[`weigth-${weight}`], styles[`align-${align}`],
    styles[`font-${font}`], className
  );
  return (
    <Element
      className={headingClassName}
      {...rest}
    >
      {children}
    </Element>
  )
}

Heading.displayName = 'Heading';
Heading.propTypes = {
  level: PropTypes.number && PropTypes.oneOf([1,2,3,4,5,6]),
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'black', 'white', 'dark-grey', 'purple'
  ]),
  weight: PropTypes.oneOf([
    'extra-light', 'light', 'normal', 'medium', 'semi-bold',
    'bold', 'extra-bold', 'black'
  ]),
  align: PropTypes.oneOf([
    'left', 'center', 'right'
  ]),
};

export default Heading;
