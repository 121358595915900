import React, { useContext, useEffect } from 'react';
import axios from 'axios';
// import Marquee from 'react-fast-marquee';
// import Marquee from "react-marquee-slider";
import Marquee from 'components/marquee/marquee';

import { MarketsStateContext } from 'contexts';

// import Ticker from 'components/ticker/ticker';

import styles from './footer.module.scss';
import './ticker.css';

const bosslifeAPI = process.env.REACT_APP_BOSSLIFE_API;
const marketPath = process.env.REACT_APP_MARKET_INVENTORY_PATH;

const TickerItem = ({ websiteLogoUrl, price, change, symbol }) => (
  <div className={styles['ticker--item']} key={symbol}>
    <img className={styles.logo} alt="" src={websiteLogoUrl}/>
    <div className={styles.content}>
      <span>
        <img
          width="24"
          height="24"
          src={
            change > 0
            ? "/images/up-change.png"
            : "/images/down-change.png"
          }
          alt=""
        />
      </span>
      <span>${price.toFixed(2)}</span>
    </div>
  </div>
)

const Footer = () => {
  const { marketsState: { markets }, setMarketsState } = useContext(MarketsStateContext);

  useEffect(() => {
    let marketsInterval;
    const getData = async () => {
      if (!markets.length) {
        const response = await axios.get(`${bosslifeAPI}${marketPath}`);
        if (response.status >= 200 && response.status < 300) {
          setMarketsState(prevState => ({
            markets: response.data
          }));
        }
      }
      marketsInterval = setInterval(async () => {
        const response = await axios.get(bosslifeAPI);
        if (response.status >= 200 && response.status < 300) {
          setMarketsState(prevState => ({
            markets: response.data
          }));
        }
      }, 15 * 60 * 1000);
    }
    getData();
    return () => {
      clearInterval(marketsInterval);
    }
  }, [markets, setMarketsState]);

  const currentDate = new Date();

  return (
    <div className={styles.container}>
      <div className={styles['footer-container']}>
        <div className={styles.ticker}>
          <img src="/images/ticker-logo.svg" alt="" className={styles['ticker-logo']} />
          <Marquee className={styles.marquee} gradient={false} speed={50}>
            {(markets.length && markets.map((market) => (
              <TickerItem {...market} key={market.symbol} />
            ))) || (<div>Loading Market Data...</div>)}
          </Marquee>
          {/*<Ticker items={markets} itemComponent={TickerItem} />*/}
        </div>
        <div className={styles.footer}>
          <div className={styles['footer-content']}>
            <div className={styles.copyright}>
              <img src="/images/logo-white.png" alt="" />
              <a
                href="https://www.notion.so/Bosslife-Investments-US-Inc-Investing-Disclosures-dd1cfaac51e44799a440125fbceb1c73"
                target="_blank"
                rel="noopener noreferrer"
              >Disclosures</a>
              <div className={styles['copyright-text']}>
                COPYRIGHT BOSSLIFE INVESTMENTS, INC. ALL RIGHTS RESERVED {currentDate.getFullYear()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer;
