import React, { useState, useEffect, useContext } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import Lottie from 'react-lottie';
import { isSafari } from 'react-device-detect';

import { ResponsiveContext } from 'contexts';

import bosslifeIdxHero from 'assets/bosslife-index/BLINDEX';
import bosslifeDailyMoves from 'assets/bosslife-index/DAILYMOVES';

import { Header, Text, Emoji } from 'components';
import Modal from 'components/modal/modal';

import BosslifeIndexQueries from './bosslife-index.queries';

import styles from './bosslife-index.module.scss';
import ScrollingIndicator from '../../components/indicators/scrolling';

const BosslifeIndex = (props) => {
  const screen = useContext(ResponsiveContext);
  const [showModal, setShowModal] = useState(false);

  const {
    isLoading: isGainerLoading,
    isError: isGainerError,
    data: biggestGainer
  } = useQuery('gainer', BosslifeIndexQueries.getBiggestGainer);
  const {
    isLoading: isTrendingLoading,
    isError: isTrendingError,
    data: trending
  } = useQuery('trending', BosslifeIndexQueries.getTrending);
  const {
    isLoading: isLoserLoading,
    isError: isLoserError,
    data: biggestLoser
  } = useQuery('loser', BosslifeIndexQueries.getBiggestLoser);
  const {
    isLoading: isIndexLoading,
    isError: isIndexError,
    data: index
  } = useQuery('blIndexChange', BosslifeIndexQueries.getIndexData);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: bosslifeIdxHero,
  };
  
  return (
    <>
      <Modal hideCross isModalOpen={showModal} containerClassName={styles['modal-container']} className={styles.modal} onClose={() => setShowModal(false)} shouldOverlayClose>
        <Text.Body size="large" align="center" color="white" weight="normal">
          Regular hours for US stock markets are 9:30 a.m. to 4 p.m. Eastern Time from Monday to Friday. The markets are closed on weekends and for holidays...
        </Text.Body>
        <br />
        <Text.Body size="large" align="center" color="white" weight="normal">
          {"But remember bitcoin never closes. It's awake 24/7/365 globally."}
        </Text.Body>
      </Modal>
      <div className={styles['page-container']}>
        <div className={styles.container}
          style={{
            backgroundImage: `url('/images/bosslife-index/bg.jpg')`,
          }}
        >
          <Header theme="light" className={styles.buffer} />
          <div className={styles['emoji-container']}>
            <video id="app-video" className={styles['app-video']} poster="/images/bosslife-index/hero.png" playsInline loop="loop" muted autoPlay controls="">
              {isSafari && <source src="/images/bosslife-index/hero.mov" type="video/mp4" />}
              <source src="/images/bosslife-index/hero.webm" type="video/webm" />
              Your browser does not support the video tags
            </video>
          </div>
          <div className={styles.today}>
            {index && index.data.computedIndex > 0 ? (<img
              src={"/images/up-today.png"}
              alt=""
            />) : (<img
              src={"/images/down-today.png"}
              alt=""
            />)}
          </div>
          <div className={styles['index-container']}>
            <div className={styles['index-value']}>
              <Text.Heading level={1} color="white" weight="black" className={styles.text}>
                {index && index.data.computedIndex.toFixed(2)}%
              </Text.Heading>
            </div>
          </div>
          <ScrollingIndicator />
        </div>
        <div className={styles['content-container']}>
          <div className={styles.hero}>
            <img src="/images/bosslife-index/bosslife-100-pink.png" alt="Bosslife 100 Index" />
            <Text.Heading align="center" level={4} color="black" weight="normal">
              We follow the major consumer spending themes that will matter for the next 10 years
            </Text.Heading>
          </div>
          <div className={styles['daily-moves-container']}>
            <Lottie
              options={{
                ...defaultOptions,
                animationData: bosslifeDailyMoves
              }}
              height={screen.greaterThan('md') ? 400 : 200}
              width={screen.greaterThan('md') ? 600 : 400}
            />
            <div className={styles['gainers-and-losers']}>
              <div className={styles.frames}>
                <div className={styles.frame}>
                  <img className={styles.type} src="/images/bosslife-index/big-gainer.png" alt="" />
                  <img className={styles.logo} src={biggestGainer && biggestGainer.data.websiteLogoUrl} alt={biggestGainer && biggestGainer.data.symbol} />
                </div>
                <div className={styles.frame}>
                  <img className={styles.type} src="/images/bosslife-index/big-loser.png" alt="" />
                  <img className={styles.logo} src={biggestLoser && biggestLoser.data.websiteLogoUrl} alt={biggestLoser && biggestLoser.data.symbol} />
                </div>
                <div className={styles.frame}>
                  <img className={styles.type} src="/images/bosslife-index/trending.png" alt="" />
                  <img className={styles.logo} src={trending && trending.data.websiteLogoUrl} alt={trending && trending.data.symbol} />
                </div>
              </div>
            </div>
         </div>
         <div className={`${styles.divider} ${styles.top}`} />
         <div className={styles.objectives}>
           <Text.Heading level={2}>Objectives</Text.Heading>
           <Text.Body size="large" align="center">
             The BL100 tracks companies favored by today's young adults, Gen Z and the emerging Gen Alpha. Our index consists of US and global companies who are household names in consumer pop culture. We look for brands with devoted fans and online communities; whose products can become a daily habit for millions of people.
           </Text.Body>
         </div>
         <div className={`${styles.divider} ${styles.bottom}`} />
         <div className={styles.categories}>
           <Text.Heading level={2}>Categories</Text.Heading>
           <img src="/images/bosslife-index/categories.png" alt="" />
         </div>
         <div className={`${styles.divider} ${styles.bottom}`} />
         <div className={styles.management}>
           <Text.Heading level={2}>Management</Text.Heading>
           <Text.Body size="large" align="center">
             The BL100 is designed to provide coverage of the brands and internet scale companies that power the lives of younger consumers.
           </Text.Body>
           <br />
           <Text.Body size="large" align="center">
             Bosslife Investments US Inc. uses qualitative and quantitative factors to identify a universe of consumer companies with thematic relevance. The Index is expected to consist of equity securities of U.S. and foreign issuers of $1B+ market capitalization, which may include common stocks and depositary receipts representing interests in foreign securities (from developed markets). The Index may also contain companies that have recently made an initial public offering (“IPO”).
           </Text.Body>
         </div>
         <div className={`${styles.divider} ${styles.bottom}`} />
         <div className={styles.countries}>
           <Text.Heading level={2}>Countries</Text.Heading>
           <div className={styles.flags}>
             <Emoji symbol="🇺🇸" />
             <Emoji symbol="🇨🇦" />
             <Emoji symbol="🇬🇧" />
             <Emoji symbol="🇯🇵" />
             <Emoji symbol="🇩🇪" />
             <Emoji symbol="🇫🇷" />
             <Emoji symbol="🇪🇸" />
             <Emoji symbol="🇸🇪" />
             <Emoji symbol="🇨🇳" />
           </div>
         </div>
         <div className={`${styles.divider} ${styles.bottom}`} />
         <div className={styles.documents}>
           <Text.Heading level={2}>
             Documents & Index Change Notices
           </Text.Heading>
           <a href="/bl100methodology.pdf" target="_blank" rel="noreferrer noopener"><img src="/images/bosslife-index/methodology.png" alt="" /></a>
         </div>
       </div>
      </div>
    </>
  );
}

export default BosslifeIndex
