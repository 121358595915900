/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import styles from './modal.module.scss';

class Modal extends React.Component {
  constructor(props) {
    super(props);

    ReactModal.setAppElement('#modal'); // we love portals
  }

  render() {
    const {
      isModalOpen,
      shouldOverlayClose = true,
      className,
      containerClassName,
      actionsInContent = false,
      header,
      customHeader,
      children,
      onClose,
      hideCross
    } = this.props;

    if (!isModalOpen) {
      return null;
    }

    return (
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={onClose}
        shouldCloseOnOverlayClick={shouldOverlayClose}
        className={`${styles.modal} ${containerClassName}`}
        overlayClassName={styles.overlay}
      >
        {onClose && !hideCross && (
          <img
            src="/images/close-icon.svg"
            className={`${styles['action-icon']} ${styles.cross}`}
            alt="Close"
            onClick={onClose}
            onKeyPress={onClose}
          />
        )}
        {header && !customHeader && (
          <div className={styles.header}>{header}</div>
        )}
        {customHeader && header}
        <div
          className={`${styles.content} ${
            actionsInContent ? styles['no-content-padding'] : ''
          } ${className || ''}`}
        >
          {children}
        </div>
      </ReactModal>
    );
  }
}

Modal.displayName = 'Modal';
Modal.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  actionsInContent: PropTypes.bool,
  customHeader: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  shouldOverlayClose: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string,
};

export default Modal;
