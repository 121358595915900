import React, { useContext, useEffect, useState } from "react";
import { Header, Text } from "../../components";
import moment from "moment";

import Modal from 'components/modal/modal';

import styles from './shop.module.scss';
import Category from "./fragments/category";
import ShopItem from "./fragments/shop-item";
import { ResponsiveContext } from "../../contexts";
import EmailGrab from "../../components/email-grab/email-grab";

const Shop = ({ data }) => {
  const screen = useContext(ResponsiveContext);
  const [showModal, setShowModal] = useState(false);

  const shuffleArray = (array) => {
    let currentIndex = array.length, randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex != 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }

  const generateCategoryGroup = (groupNum) => {
    switch(groupNum) {
      case 2:
        return (
          <>
            <Category category="food" />
            <Category category="tech" />
            <Category category="gaming" />
            <Category category="shop" />
          </>
        )
      default:
        return (
          <>
            <Category category="beauty" />
            <Category category="fashion" />
            <Category category="fun" />
            <Category category="cars" />
          </>
        )
    }
  }

  return (
    <>
      <Modal hideCross isModalOpen={showModal} containerClassName={styles['modal-container']} className={styles.modal} onClose={() => setShowModal(false)} shouldOverlayClose>
        <EmailGrab className={styles['modal-email-grab']} />
      </Modal>
      <div className={styles.container}>
        <Header theme="light" />
        <div className={styles['content-container']}>
          <div className={styles.hero}>
            {screen.lessThan('md') && (
              <img src="/images/shop/bl100-icon.png" alt="" className={styles['hero-img']} />
            )}
            <div className={styles['hero-content']}>
              <Text.Heading level={2} align="center" color="white">Own Your Favorite Brands</Text.Heading>
              <div className={styles.categories}>
                {screen.greaterThan('sm') && generateCategoryGroup(1)}
                {screen.greaterThan('sm') && generateCategoryGroup(2)}
                {screen.lessThan('md') && (
                  <>
                    <div className={styles['category-group']}>
                      {generateCategoryGroup(1)}
                    </div>
                    <div className={styles['category-group']}>
                      {generateCategoryGroup(2)}
                    </div>
                  </>
                )}
              </div>
            </div>
            {screen.greaterThan('sm') && (
              <img src="/images/shop/bl100-icon.png" alt="" className={styles['hero-img']} />
            )}
          </div>
          <div className={styles['shop-items']}>
            {shuffleArray(data).map((item) => <ShopItem onClick={() => setShowModal(true)} item={item} key={item.symbol} />)}
          </div>
        </div>
      </div>
    </>
  )
}

export default Shop;