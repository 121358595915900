import React, { useState, createContext } from 'react';

import screenMap from 'css/media-queries.module.scss';

export const ResponsiveContext = createContext();

export const ResponsiveConsumer = ResponsiveContext.Consumer;

export const ResponsiveProvider = (props) => {
  const [screen, setScreen] = useState(null);

  const updateScreen = (newSize) => {
    if (screen !== newSize) setScreen(newSize);
  };
  const setActiveScreenSize = () => {
    const screenWidth = window.innerWidth;
    const widths = Object.values(screenMap).map((value) => parseInt(value, 10));
    const sizes = Object.keys(screenMap);
    const lastIndex = widths.length - 1;

    for (let i = 0; i < sizes.length; i++) {
      if (screenWidth <= widths[0]) updateScreen(sizes[0]);
      else if (screenWidth >= widths[lastIndex]) updateScreen(sizes[lastIndex]);
      else if (screenWidth >= widths[i] && screenWidth < widths[i + 1]) {
        updateScreen(sizes[i]);
      }
    }
  };

  const getActive = () => {
    return screen;
  };
  const lessThan = (target) => {
    return parseInt(screenMap[screen], 10) < parseInt(screenMap[target], 10);
  };
  const greaterThan = (target) => {
    return parseInt(screenMap[screen], 10) > parseInt(screenMap[target], 10);
  };
  const getValue = (target) => {
    return parseInt(screenMap[target], 10);
  };

  if (!screen) {
    setActiveScreenSize();
    window.addEventListener('resize', setActiveScreenSize);
  }

  return (
    <ResponsiveContext.Provider
      value={{
        getActive,
        lessThan,
        greaterThan,
        getValue,
      }}
    >
      {props.children}
    </ResponsiveContext.Provider>
  );
};
