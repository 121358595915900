import React, { useContext, useEffect, useState } from "react";
import { MarketsStateContext } from "../../contexts";
import { ShopStateContext } from "../../contexts/shop.context";
import Shop from "./shop";

const ShopContainer = () => {
  const { shopState } = useContext(ShopStateContext);
  const { marketsState: { markets: data } } = useContext(MarketsStateContext);
  const [dataToDisplay, setDataToDisplay] = useState(data);
  useEffect(() => {
    const { filters } = shopState;
    if (filters.length === 0) setDataToDisplay(data);
    if (filters.length > 0) {
      setDataToDisplay(data.filter(d => {
        const category = d.category === "entertainment" ? "fun" : d.category === "shopping" ? "shop" : d.category;
        return filters.includes(category)
      }));
    }
  }, [shopState, data]);

  return <Shop data={dataToDisplay} />
}

export default ShopContainer;