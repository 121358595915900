import React, { useState, useEffect, useContext } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import Lottie from 'react-lottie';

import { ResponsiveContext } from 'contexts';

import happyFace from 'assets/market-mood/faces/happy-face';
import sadFace from 'assets/market-mood/faces/sad-face';
import worriedFace from 'assets/market-mood/faces/worried-face';
import heartsFace from 'assets/market-mood/faces/happy-hearts-face';
import nightSadFace from 'assets/market-mood/faces/sleep-sad-face';
import nightHappyFace from 'assets/market-mood/faces/sleep-happy-face';

import { Header, Text } from 'components';
import Modal from 'components/modal/modal';

import { getMarketMood, getMarketHours } from './market.queries';

import styles from './market-mood.module.scss';

const MarketMood = (props) => {
  const screen = useContext(ResponsiveContext);
  const [background, setBackground] = useState('green');
  const [face, setFace] = useState(happyFace);
  const [showModal, setShowModal] = useState(false);

  const {
    isLoading: isHoursLoading,
    isError: isHoursError,
    data: marketHours
  } = useQuery('hours', getMarketHours);
  const {
    isLoading: isMoodLoading,
    isError: isMoodError,
    data: marketMood
  } = useQuery('mood', getMarketMood);

  useEffect(() => {
    if (marketHours && marketHours.data) {
      const isMarketOpen = marketHours.data.isTheStockMarketOpen;
      if (marketMood && marketMood.data) {
        const marketChange = marketMood.data.changesPercentage;
        if (!isMarketOpen) {
          if (marketChange > 0) {
            setBackground('night-green');
            setFace(nightHappyFace);
          } else {
            setBackground('night-red');
            setFace(nightSadFace);
          }
        } else {
          if (marketChange < -0.5) {
            setBackground('red');
            setFace(sadFace);
          } else if (marketChange < 0) {
            setBackground('purple');
            setFace(worriedFace);
          } else if (marketChange < 0.5) {
            setBackground('pink');
            setFace(happyFace);
          } else if (marketChange < 1) {
            setBackground('green');
            setFace(happyFace);
          } else {
            setBackground('green');
            setFace(heartsFace);
          }
        }
      }
    }
  }, [marketMood, marketHours])


  // (marketHours && marketHours.data && !marketHours.data.isTheStockMarketOpen && "/images/market-mood/closed.png" ) || (marketMood && marketMood.data.changesPercentage < 0 ? "/images/down-today.png" : "/images/up-today.png")
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: face,
  };
  return (
    <>
      <Modal hideCross isModalOpen={showModal} containerClassName={styles['modal-container']} className={styles.modal} onClose={() => setShowModal(false)} shouldOverlayClose>
        <Text.Body size="large" align="center" color="white" weight="normal">
          Regular hours for US stock markets are 9:30 a.m. to 4 p.m. Eastern Time from Monday to Friday. The markets are closed on weekends and for holidays...
        </Text.Body>
        <br />
        <Text.Body size="large" align="center" color="white" weight="normal">
          {"But remember bitcoin never closes. It's awake 24/7/365 globally."}
        </Text.Body>
      </Modal>
      <div className={styles.container}
        style={{
          backgroundImage: `url('/images/market-mood/backgrounds/${background}.new.png')`
        }}
      >
        <Header theme="light" className={styles.buffer} />
        <div className={styles['market-mood']}>
          <div className={styles['title-icon']}>
            <img src="/images/market-mood/icon.png" alt="Market Mood" />
          </div>
          <div className={styles['emoji-container']}>
            <Lottie
             options={defaultOptions}
             height={screen.greaterThan('lg') && window.innerHeight > 1000 ? 400 : 200}
             width={screen.greaterThan('lg') && window.innerHeight > 1000 ? 400 : 200}
           />
          </div>
          <div className={styles.today}>
            <img
              src={
                marketMood
                  && marketMood.data.changesPercentage < 0
                    ? "/images/down-today.png"
                    : "/images/up-today.png"
              }
              alt=""
            />
          </div>
          <div className={styles['percent-container']}>
            <div className={styles.percent}>
              <Text.Heading level={1} color="white" weight="black" className={styles['percent-text']}>
                {marketMood && marketMood.data.changesPercentage.toFixed(2)}%
              </Text.Heading>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MarketMood
