import React, { createContext, useState } from 'react';

export const MarketsStateContext = React.createContext();
export const MarketsStateConsumer = MarketsStateContext.Consumer;
export const MarketsStateProvider = (props) => {
  const [marketsState, setMarketsState] = useState({
    markets: []
  });
  return (
    <MarketsStateContext.Provider value={{ marketsState, setMarketsState }}>
      {props.children}
    </MarketsStateContext.Provider>
  )
}
