import React from 'react';

import beauty from 'assets/shop/icons/beauty.svg';
import cars from 'assets/shop/icons/car.svg';
import fashion from 'assets/shop/icons/fashion.svg';
import food from 'assets/shop/icons/food.svg';
import fun from 'assets/shop/icons/fun.svg';
import gaming from 'assets/shop/icons/gaming.svg';
import shop from 'assets/shop/icons/shop.svg';
import tech from 'assets/shop/icons/tech.svg';

import styles from '../shop.module.scss';
import { Text } from '../../../components';

const IconMap = {
  beauty: {
    icon: beauty,
    width: '24px'
  },
  cars: {
    icon: cars,
    width: '24px',
  },
  fashion: {
    icon: fashion,
    width: '24px',
  },
  food: {
    icon: food,
    width: '24px',
  },
  entertainment: {
    icon: fun,
    width: '17px',
  },
  gaming: {
    icon: gaming,
    width: '22px',
  },
  shopping: {
    icon: shop,
    width: '17px',
  },
  tech: {
    icon: tech,
    width: '24px',
  }
}

const ShopItem = ({ item, onClick }) => {
  if (!item) return null;
  return (
    <div className={styles['shop-item']} onClick={onClick}>
      <div className={styles['item-container']} style={{ backgroundImage: `url('${item.shopTileUrl}')`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <img src={`/images/shop/corner-${item.category.toLowerCase()}.svg`} className={styles.corner} alt="" />
        <img src={IconMap[item.category.toLowerCase()].icon} width={`${IconMap[item.category.toLowerCase()].width}`} className={styles['category-icon']} alt={item.category} />
        <img src={item.websiteLogoUrl} width="70" height="auto" alt={item.symbol} />
      </div>
      <div className={styles.footer}>
        <img className={styles.change} src={item.changesPercentage < 0 ? 'images/shop/down.svg' : 'images/shop/up.svg'} alt="" />
        <Text.Body weight="bold" size="large" color="black">{item.changesPercentage.toFixed(2)}%</Text.Body>
      </div>
    </div>
  )
};

export default ShopItem;