import React, { useState, useRef, useEffect } from 'react';
import './marquee.scss';

const Marquee = ({
  style = {},
  className = "",
  play = true,
  pauseOnHover = false,
  pauseOnClick = false,
  direction = "left",
  speed = 20,
  delay = 0,
  gradient = true,
  gradientColor = [255, 255, 255],
  gradientWidth = 200,
  children,
}) => {
  /* React Hooks */
  const [containerWidth, setContainerWidth] = useState(0);
  const [marqueeWidth, setMarqueeWidth] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isMounted, setIsMounted] = useState(false);

  const containerRef = useRef(null);
  const marqueeRef = useRef(null);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    /* Find width of container and width of marquee */
    if (marqueeRef.current && containerRef.current) {
      setContainerWidth(containerRef.current.getBoundingClientRect().width);
      setMarqueeWidth(marqueeRef.current.getBoundingClientRect().width);
    }

    if (marqueeWidth < containerWidth) {
      setDuration(containerWidth / speed);
    } else {
      setDuration(marqueeWidth / speed);
    }
  });

  // Gradient color in an unfinished rgba format
  const rgbaGradientColor = `rgba(${gradientColor[0]}, ${gradientColor[1]}, ${gradientColor[2]}`;

  return (
    <React.Fragment>
      {!isMounted ? null : (
        <div
          ref={containerRef}
          style={{
            ...style,
            "--pause-on-hover": pauseOnHover ? "paused" : "running",
            "--pause-on-click": pauseOnClick ? "paused" : "running",
          }}
          className={className + " marquee-container"}
        >
          {gradient && (
            <div
              style={{
                "--gradient-color": `${rgbaGradientColor}, 1), ${rgbaGradientColor}, 0)`,
                "--gradient-width":
                  typeof gradientWidth === "number"
                    ? `${gradientWidth}px`
                    : gradientWidth,
              }}
              className="overlay"
            />
          )}
          <div
            ref={marqueeRef}
            style={{
              "--play": play ? "running" : "paused",
              "--direction":
                direction === "left" ? "normal" : "reverse",
              "--duration": `${duration}s`,
              "--delay": `${delay}s`,
              "--margin-right": `${
                marqueeWidth < containerWidth
                  ? containerWidth - marqueeWidth
                  : 0
              }px`,
            }}
            className="marquee"
          >
            {children}
          </div>
          <div
            style={{
              "--play": play ? "running" : "paused",
              "--direction":
                direction === "left" ? "normal" : "reverse",
              "--duration": `${duration}s`,
              "--delay": `${delay}s`,
              "--margin-right": `${
                marqueeWidth < containerWidth
                  ? containerWidth - marqueeWidth
                  : 0
              }px`,
            }}
            className="marquee"
          >
            {children}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Marquee;
