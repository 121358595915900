import React, { useContext } from "react";

import beauty from 'assets/shop/categories/beauty.svg';
import inactiveBeauty from 'assets/shop/categories/inactive-beauty.svg';
import cars from 'assets/shop/categories/cars.svg';
import inactiveCars from 'assets/shop/categories/inactive-cars.svg';
import fashion from 'assets/shop/categories/fashion.svg';
import inactiveFashion from 'assets/shop/categories/inactive-fashion.svg';
import food from 'assets/shop/categories/food.svg';
import inactiveFood from 'assets/shop/categories/inactive-food.svg';
import fun from 'assets/shop/categories/fun.svg';
import inactiveFun from 'assets/shop/categories/inactive-fun.svg';
import gaming from 'assets/shop/categories/gaming.svg';
import inactiveGaming from 'assets/shop/categories/inactive-gaming.svg';
import shop from 'assets/shop/categories/shop.svg';
import inactiveShop from 'assets/shop/categories/inactive-shop.svg';
import tech from 'assets/shop/categories/tech.svg';
import inactiveTech from 'assets/shop/categories/inactive-tech.svg';

import styles from '../shop.module.scss';
import { ShopStateContext } from "../../../contexts/shop.context";

const CategoryMap = {
  beauty: {
    active: beauty,
    inactive: inactiveBeauty
  },
  cars: {
    active: cars,
    inactive: inactiveCars
  },
  fashion: {
    active: fashion,
    inactive: inactiveFashion
  },
  food: {
    active: food,
    inactive: inactiveFood
  },
  fun: {
    active: fun,
    inactive: inactiveFun
  },
  gaming: {
    active: gaming,
    inactive: inactiveGaming
  },
  shop: {
    active: shop,
    inactive: inactiveShop
  },
  tech: {
    active: tech,
    inactive: inactiveTech
  }
}

const Category = ({ category }) => {
  const { shopState, setShopState } = useContext(ShopStateContext);
  const { filters } = shopState;
  const isActive = filters.length > 0 && filters.includes(category);

  const handleCategoryClick = () => {
    const newFilters = filters;
    if (isActive) {
      setShopState({ filters: newFilters.filter(ac => ac !== category) });
    } else {
      newFilters.push(category);
      setShopState({ filters: newFilters });
    }
  }

  return (
    <div className={styles.category} onClick={handleCategoryClick}>
      <img 
        width="43px" 
        height="35px" 
        src={
          isActive 
            ? CategoryMap[category].active 
            : CategoryMap[category].inactive
        }
        alt={category.toUpperCase()}
      />
    </div>
  )
}

export default Category;