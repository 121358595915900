import React, { createContext, useState } from 'react';

export const ShopStateContext = createContext();
export const ShopStateConsumer = ShopStateContext.Consumer;
export const ShopStateProvider = (props) => {
  const [shopState, setShopState] = useState({
    filters: [],
  });
  return (
    <ShopStateContext.Provider value={{ shopState, setShopState }}>
      {props.children}
    </ShopStateContext.Provider>
  )
}
