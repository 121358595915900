import axios from 'axios';

const bosslifeAPI = process.env.REACT_APP_BOSSLIFE_API;
const indexPath = process.env.REACT_APP_INDEX_PATH;
const biggestGainerPath = process.env.REACT_APP_MARKET_BIGGEST_GAINER_PATH;
const biggestLoserPath = process.env.REACT_APP_MARKET_BIGGEST_LOSER_PATH;
const trendingPath = process.env.REACT_APP_MARKET_TRENDING_PATH;

export const getIndexData = () => {
  return axios.get(`${bosslifeAPI}${indexPath}`);
}

export const getBiggestGainer = () => {
  return axios.get(`${bosslifeAPI}${biggestGainerPath}`);
}

export const getBiggestLoser = () => {
  return axios.get(`${bosslifeAPI}${biggestLoserPath}`);
}

export const getTrending = () => {
  return axios.get(`${bosslifeAPI}${trendingPath}`);
}

export default {
  getIndexData,
  getBiggestLoser,
  getBiggestGainer,
  getTrending,
}
