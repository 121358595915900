import React, { createContext, useState } from 'react';

export const AppStateContext = createContext();
export const AppStateConsumer = AppStateContext.Consumer;

export const AppStateProvider = ({ children }) => {
  const [appState, setAppState] = useState({
    user: {
      isAuthenticated: null
    }
  });

  return (
    <AppStateContext.Provider value={{ appState, setAppState }}>
      {children}
    </AppStateContext.Provider>
  )
}
