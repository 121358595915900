import { useEffect, useContext } from 'react';
import firebase from 'firebase';

import { AppStateContext } from 'contexts';


const RouteObserver = (props) => {
  const { appState, setAppState } = useContext(AppStateContext);
  const db = firebase.firestore();

  //side effect:
  // checks authentication
  // -> if authenticated (through firebase) get current user and populate user object in app state
  // -> if not authenticated, reset user object (if populated)
  useEffect(() => {
    const checkAuthentication = async () => {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          // User is signed in.
          await db.collection('users').doc(user.uid).get().then((snapshot) => {
            const firestoreUser = snapshot.data();
            setAppState((prevState) => ({
              ...prevState,
              user: {
                ...prevState.user,
                ...firestoreUser,
                isAuthenticated: true
              }
            }));
          });
        } else {
          // No user is signed in.
          setAppState((prevState) => ({
            ...prevState,
            user: {
              ...prevState.user,
              isAuthenticated: false
            }
          }));
        }
      });
    }
    if (!appState.user.isAuthenticated) {
      checkAuthentication();
    }
  }, [setAppState, appState.user.isAuthenticated]);
  return null;
}

export default RouteObserver;
