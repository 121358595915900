import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Footer } from 'components';

import { Landing, Bitcoin, MarketMood, BosslifeIndex, Shop } from './views';

const AppRouter = () => {
  return (
    <>
      <Switch>
        <Route exact path="/"><Landing /></Route>
        <Route path="/bitcoin"><Bitcoin /></Route>
        <Route path="/market-mood"><MarketMood /></Route>
        <Route path="/100"><BosslifeIndex /></Route>
        <Route path="/shop"><Shop /></Route>
      </Switch>
      <Footer />
    </>
  )
}

export default AppRouter;
