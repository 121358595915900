import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { FirebaseAuthProvider } from '@react-firebase/auth';
import { QueryClient, QueryClientProvider } from 'react-query';

import { AppStateProvider, MarketsStateProvider, ResponsiveProvider } from 'contexts';
import { firebaseConfig } from 'config';

import { RouteObserver } from 'observers';

import AppRouter from './router';
import { ShopStateProvider } from './contexts/shop.context';

import './App.css';
import './fonts/Excluded.ttf';
import './fonts/ExcludedItalic.ttf';

const queryClient = new QueryClient();

const App = () => {
  return (
    <FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>
      <QueryClientProvider client={queryClient}>
        <AppStateProvider>
          <ResponsiveProvider>
            <MarketsStateProvider>
              <ShopStateProvider>
                <Router>
                  <RouteObserver />
                  <AppRouter />
                </Router>
              </ShopStateProvider>
            </MarketsStateProvider>
          </ResponsiveProvider>
        </AppStateProvider>
      </QueryClientProvider>
    </FirebaseAuthProvider>
  )
}

export default App;
