const config = {
  apiKey: "AIzaSyAMst0399XbDEvan8vN5h7yxGPCYw-f4J4",
  authDomain: "boifefunfinancefeed.firebaseapp.com",
  projectId: "boifefunfinancefeed",
  storageBucket: "boifefunfinancefeed.appspot.com",
  messagingSenderId: "757283447956",
  appId: "1:757283447956:web:44a6e32263f15ee36e8081",
  measurementId: "G-7ELZTTWMDQ"
}

export default config;
