import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { isSafari } from 'react-device-detect';

import { Header, Text } from 'components';

import bitcoinPriceBg from '../../assets/bitcoin/bitcoin-change-bg.png';
import upArrow from 'assets/bitcoin/upArrow.png';
import downArrow from 'assets/bitcoin/downArrow.png';

import styles from './bitcoin.module.scss';

const bosslifeAPI = process.env.REACT_APP_BOSSLIFE_API;
const bitcoinPricePath = process.env.REACT_APP_BTC_PRICE_PATH;

const Bitcoin = () => {
  const [btc, setBtc] = useState({});

  useEffect(() => {
    let btcInterval;
    const getBtcPrice = async () => {
      const price = await axios.get(`${bosslifeAPI}${bitcoinPricePath}`);
      if (price.status >= 200 && price.status < 300) {
        setBtc(price.data[0]);
      }
    }
    if (btc.readyStatus !== 'ready') {
      getBtcPrice();
    }

    btcInterval = setInterval(() => getBtcPrice(), 3000);

    return () => {
      clearInterval(btcInterval);
    }
  }, []);

  return (
    <div className={styles.container}>
      <Header theme="light" />
      <div className={styles['bitcoin-price']}>
        <div className={styles['price-container']}>
          <img src={bitcoinPriceBg} className={styles.bg} alt="" />
          <div className={styles['info-container']}>
            <div className={styles.info}>
              <img src={btc && btc.changesPercentage > 0 ? upArrow : downArrow} className={styles.arrow} alt="" />
              <div className={styles['logo-and-percentage']}>
                <img src="/images/bitcoin/bitcoin.svg" alt="" />
                <div className={styles.percentage} style={{ backgroundColor: btc && btc.changesPercentage > 0 ? '#33EE34' : '#FF3D38' }}>
                  <Text.Body size="small" weight="bold">{btc && btc.changesPercentage ? btc.changesPercentage.toFixed(2) : '0.00'}%</Text.Body>
                </div>
              </div>
            </div>
            <Text.Heading level={1}>{btc && btc.price ? btc.price.toLocaleString("en-US", {style:"currency", currency:"USD"}) : '$0.00'}</Text.Heading>
          </div>
        </div>
        <div className={styles.coin}>
          <video id="app-video" className={styles['app-video']} poster="/images/bitcoin/bosscoin.png" playsInline loop="loop" muted autoPlay controls="">
            {isSafari && <source src="/images/bitcoin/bosscoin.mov" type="video/mp4" />}
            <source src="/images/bitcoin/bosscoin.webm" type="video/webm" />
            Your browser does not support the video tags
          </video>
        </div>
      </div>
    </div>
  )
}

export default Bitcoin;
