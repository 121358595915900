import React, { useState, useEffect } from 'react';
import axios from 'axios';

import styles from './email-grab.module.scss';
import Text from '../text/text';
import { Input } from '../inputs';
import { RotatingLines } from 'react-loader-spinner';

const EmailGrab = ({ className }) => {
  const [subscriberEmail, setSubscriberEmail] = useState();
  const [isSubscribing, setIsSubscribing] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const handleSubmit = async () => {
    if (!subscriberEmail) return;
    setIsSubscribing(true);
    const url = 'https://script.google.com/macros/s/AKfycbzdMPGsHAZ8p7QCLK9bFr7CVyMzDrQbGcYlQTxONL_v_OYklkWB8vONCzsWc2Qp5h2opQ/exec';
    const formData = new FormData();
    formData.append("Email", subscriberEmail);
    formData.append("CreatedAt", new Date());

    await axios.post(url, formData);
    setSubscriberEmail('');
    setIsSubscribing(false);
    setIsSubscribed(true);
  }

  useEffect(() => {
    let subscribedTimeout;
    if (isSubscribed) {
      subscribedTimeout = setTimeout(() => setIsSubscribed(false), 2000);
    }

    return () => {
      if (subscribedTimeout) {
        clearTimeout(subscribedTimeout);
      }
    }
  }, [isSubscribed]);

  return (
    <div className={`${styles['email-container']} ${className}`}>
      <div className={styles['email-box']}>
        <Text.Body size="small" color="white" weight="normal">
          Add your email and we’ll send an invite soon
        </Text.Body>
        <div className={styles['input-container']}>
          {!isSubscribed && (
            <Input
              name="email"
              type="email"
              value={subscriberEmail}
              placeholder="name@email.com"
              onChange={(e) => setSubscriberEmail(e.target.value)}
            />
          )}
          {isSubscribed && (
            <Text.Body className={styles['subscribed-text']} size="large">Thanks for subscribing!</Text.Body>
          )}
          {isSubscribing && (
            <RotatingLines
              strokeColor="#45A041"
              strokeWidth="5"
              animationDuration="0.75"
              width="20px"
              visible={true}
            />
          )}
          {!isSubscribing && !isSubscribed && (
            <button className={styles['email-submit']} onClick={handleSubmit}>
              <img src="/images/arrow-right.svg" height="100%" width="auto" alt="" />
            </button>
          )}
        </div>
      </div>
      <img className={styles.mail} src="/images/mail.svg" alt="" />
    </div>
  );
}

export default EmailGrab;