import React, { useEffect, useState } from 'react';
import { isSafari } from 'react-device-detect';

import { Header, Text, Input } from 'components';

import styles from './landing.module.scss';
import axios from 'axios';
import { RotatingLines } from 'react-loader-spinner';
import EmailGrab from '../../components/email-grab/email-grab';

const Landing = () => {
  useEffect(() => {
    const [body] = document.getElementsByTagName('body');

    body.addEventListener('click touchstart', () => {
      const videoElement = document.getElementById('app-video');
      if (videoElement.playing) {
        // video is already playing so do nothing
      }
      else {
        // video is not playing
        // so play video now
        videoElement.play();
      }
    })
  }, []);

  return (
    <>
      <div className={styles.container}>
        <Header theme="light" />
        <div className={styles['content-container']}>
          <div className={styles.content}>
            <Text.Heading
              level={1} color="white" weight="black"
              align="center" className={styles.title}
            >
              A New Kind of
            </Text.Heading>
            <Text.Heading
              level={1} color="white" weight="black"
              align="center" className={styles.title}
            >
              Investment Advisor
            </Text.Heading>
            <Text.Body
              size="large" color="white"
              weight="bold"
              className={styles.tagline}
            >
              Bosslife is the fun way to shop for stocks
            </Text.Body>
            <div className={styles['actions-container']}>
              <EmailGrab />
            </div>
          </div>
          <div className={styles['product-image']}>
            {/* <img
              src="/images/bosslife-loop.gif"
              className={styles['app-video']}
              alt=""
            />
            <img
              alt=""
              src="/images/iphone-image.png"
              className={styles.product}
            /> */}
            <video id="app-video" className={styles['app-video']} poster="/images/bosslifePhoneStill.png" playsInline loop="loop" muted autoPlay controls="">
              {isSafari && <source src="/images/bosslifePhone.mov" type="video/mp4" />}
              <source src="/images/bosslifePhone.webm" type="video/webm" />
              Your browser does not support the video tags
            </video>
          </div>
        </div>
      </div>
    </>
  );
}

export default Landing;
