import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../text.module.scss';

const Body = ({
  size = 'regular',
  children = '',
  className = '',
  color = 'black',
  weight = 'normal',
  align = 'left',
  ...rest
}) => {
  const bodyClassName = classNames(
    styles[`body-${size}`], styles[color],
    styles[`weight-${weight}`], styles[`align-${align}`],
    className
  );

  return (
    <div className={bodyClassName} {...rest}>
      {children}
    </div>
  )
}

Body.displayName = 'Body';
Body.propTypes = {
  size: PropTypes.oneOf(['tiny', 'small', 'regular', 'large', 'x-large']),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'black', 'white', 'dark-grey', 'purple'
  ]),
  weight: PropTypes.oneOf([
    'extra-light', 'light', 'normal', 'medium', 'semi-bold',
    'bold', 'extra-bold', 'black'
  ]),
  align: PropTypes.oneOf([
    'left', 'center', 'right'
  ]),
}

export default Body
