import axios from 'axios';

const bosslifeAPI = process.env.REACT_APP_BOSSLIFE_API;
const marketMoodPath = process.env.REACT_APP_MARKET_MOOD_PATH;
const marketHoursPath = process.env.REACT_APP_MARKET_HOURS_PATH;

export const getMarketMood = () => {
  return axios.get(`${bosslifeAPI}${marketMoodPath}`);
}

export const getMarketHours = () => {
  return axios.get(`${bosslifeAPI}${marketHoursPath}`);
}
