import React, { useEffect, useContext } from 'react';
import firebase from 'firebase';

import { AppStateContext } from 'contexts';

const LoginButton = ({ onSuccess }) => {
  const { setAppState } = useContext(AppStateContext);
  const db = firebase.firestore();
  window.snapKitInit = function () {
    var loginButtonIconId = "login-button";
    // Mount Login Button
    window.snap.loginkit.mountButton(loginButtonIconId, {
      clientId: process.env.REACT_APP_SNAP_CLIENT_ID,
      redirectURI: `${window.location.origin}/login/snapchat/callback`,
      scopeList: [
        "user.display_name",
        "user.bitmoji.avatar",
        "user.external_id",
      ],
      handleResponseCallback: async function () {
        window.snap.loginkit.fetchUserInfo().then(
          async (result) => {
            const displayName = result.data.me.displayName;
            const [firstName] = displayName.split(' ');
            const externalId = result.data.me.externalId;
            const users = [];
            await db.collection('users').where('externalId', '==', externalId)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  // doc.data() is never undefined for query doc snapshots
                  users.push({
                    id: doc.id,
                    ...doc.data()
                  });
                });
              })
              .catch((error) => {
                  console.log("Error getting documents: ", error);
              });
            if (!users.length) {
              const email = `${firstName.toLowerCase()}+${externalId}@gmail.com`;
              const password = externalId;
              firebase.auth().createUserWithEmailAndPassword(email, password)
                .then(async (userCredential) => {
                  // Signed in
                  const user = userCredential.user;
                  db.collection('users').doc(user.uid).set({
                    displayName,
                    externalId,
                    avatar: result.data.me.bitmoji.avatar,
                    createdAt: new Date(),
                  });
                  setAppState((prevState) => ({
                    ...prevState,
                    user: {
                      ...prevState.user,
                      isAuthenticated: true,
                      email: user.email,
                      uid: user.uid,
                      displayName,
                      externalId,
                      avatar: result.data.me.bitmoji.avatar,
                      createdAt: new Date(),
                    }
                  }));
                  await firebase.auth().updateProfile({ displayName });
                  onSuccess();
                })
                .catch((error) => {
                  const errorCode = error.code;
                  const errorMessage = error.message;
                  console.error('Error::', errorCode, errorMessage);
                });
            } else {
              const [user] = users;
              const email = `${firstName.toLowerCase()}+${user.externalId}@gmail.com`;
              const password = user.externalId;
              firebase.auth().signInWithEmailAndPassword(email, password)
                .then((userCredential) => {
                  // Signed in
                  setAppState((prevState) => ({
                    ...prevState,
                    user: {
                      ...prevState.user,
                      isAuthenticated: true,
                      email: user.email,
                      uid: user.uid,
                      displayName,
                      externalId,
                      avatar: result.data.me.bitmoji.avatar,
                      createdAt: new Date(user.createdAt.seconds * 1000),
                    }
                  }));
                  onSuccess();
                })
                .catch((error) => {
                  const errorCode = error.code;
                  const errorMessage = error.message;
                  console.error(errorCode, errorMessage)
                });
            }
          },
          function (err) {
            console.log(err); // Error
          }
        );
      },
    });
  };
  // Load the SDK asynchronously
  (function (d, s, id) {
    var js,
      sjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = "https://sdk.snapkit.com/js/v1/login.js";
    sjs.parentNode.insertBefore(js, sjs);
  })(document, "script", "loginkit-sdk");

  useEffect(() => { window.snap && window.snapKitInit && window.snapKitInit() }, []);
  return (
    <div id="login-button" />
  )
}

export default LoginButton;
