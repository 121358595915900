import React, { useState, useContext } from 'react';
import cx from 'classnames';
import firebase from 'firebase';

import { AppStateContext } from 'contexts';

import { NavLink } from 'react-router-dom';
import { Text } from 'components';
import Modal from 'components/modal/modal';
import LoginButton from 'components/login-button/login-button';

import styles from './header.module.scss';

const Header = ({ theme = 'dark', className }) => {
  const { appState, setAppState } = useContext(AppStateContext);
  const [isMobileHeaderOpen, setIsMobileHeaderOpen] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);

  const mobileClass = cx(styles['navbar-mobile'], {
    [styles.open]: isMobileHeaderOpen,
    [styles.closed]: !isMobileHeaderOpen
  });

  const toggleMobileMenu = () => {
    setIsMobileHeaderOpen(prevState => {
      return !prevState
    });
  }

  const toggleModal = () => {
    setShowLoginModal(prevState => !prevState);
  }

  return (
    <>
      <Modal isModalOpen={showLoginModal} onClose={toggleModal}>
        <div className={styles['login-modal']}>
          <Text.Heading level={2} align="center">Sign Up / Log In</Text.Heading>
          <LoginButton onSuccess={toggleModal} />
        </div>
      </Modal>
      <div className={`${styles['header-container']} ${className}`}>
        <div className={styles.header}>
          <div className={styles.logo}>
            <a href="/"><img src="/images/logo.png" className={styles['logo-img']} alt="Logo" /></a>
          </div>
          <div className={styles.menu}>
            <NavLink
              to="/bitcoin" 
              activeClassName={`${styles['link--active']} ${styles[theme]}`} 
              className={`${styles.link} ${styles[theme]}`}
            >
              <Text.Body
                className={styles['link-text']} 
                color={theme === 'dark' ? 'black' : 'white'} 
                size="large" 
                weight="black" 
                align="center"
              >
                BITCOIN
              </Text.Body>
            </NavLink>
            <NavLink
              to="/market-mood"
              activeClassName={`${styles['link--active']} ${styles[theme]}`}
              className={`${styles.link} ${styles[theme]}`}
            >
              <Text.Body className={styles['link-text']} color={theme === 'dark' ? 'black' : 'white'} size="large" weight="black" align="center">
                MARKET MOOD
              </Text.Body>
            </NavLink>
            <NavLink
              to="/shop"
              activeClassName={`${styles['link--active']} ${styles[theme]}`}
              className={`${styles.link} ${styles[theme]}`}
            >
              <Text.Body className={styles['link-text']} color={theme === 'dark' ? 'black' : 'white'} size="large" weight="black" align="center">
                SHOP
              </Text.Body>
            </NavLink>
            <NavLink
              to="/100"
              activeClassName={`${styles['link--active']} ${styles[theme]}`} 
              className={`${styles.link} ${styles[theme]}`}
            >
              <Text.Body className={styles['link-text']} color={theme === 'dark' ? 'black' : 'white'} size="large" weight="black" align="center">
                BOSSLIFE100 INDEX
              </Text.Body>
            </NavLink>
          </div>
          <div className={styles['mobile-menu']}>
            <button className={styles['mobile-menu-btn']} onClick={() => toggleMobileMenu()} onKeyPress={() => toggleMobileMenu()}>
              <img src={theme === 'dark' ? "/images/menu-icon.svg" : "/images/menu-icon-white.svg"} alt="" />
            </button>
            <div className={mobileClass}>
              <div className={styles['navbar-mobile--menu']}>
                <div className={styles.actions}>
                  <button className={styles['mobile-close-btn']} onClick={() => toggleMobileMenu()} onKeyPress={() => toggleMobileMenu()}>
                    <img src="/images/close-icon.svg" alt="" />
                  </button>
                </div>
                <div className={styles.links}>
                  <NavLink
                    to="/bitcoin" 
                    activeClassName={`${styles['link--active']}`}
                    className={`${styles.link} ${styles[theme]}`}
                  >
                    <Text.Body
                      className={styles['link-text']}
                      size="large" 
                      weight="black" 
                      align="left"
                    >
                      BITCOIN
                    </Text.Body>
                  </NavLink>
                  <NavLink
                    to="/market-mood"
                    activeClassName={`${styles['link--active']}`}
                    className={`${styles.link} ${styles[theme]}`}
                  >
                    <Text.Body className={styles['link-text']} size="large" weight="black" align="left">
                      MARKET MOOD
                    </Text.Body>
                  </NavLink>
                  <NavLink
                    to="/shop"
                    activeClassName={`${styles['link--active']}`}
                    className={`${styles.link} ${styles[theme]}`}
                  >
                    <Text.Body className={styles['link-text']} size="large" weight="black" align="left">
                      SHOP
                    </Text.Body>
                  </NavLink>
                  <NavLink
                    to="/100"
                    activeClassName={`${styles['link--active']}`}
                    className={`${styles.link} ${styles[theme]}`}
                  >
                    <Text.Body className={styles['link-text']} size="large" weight="black" align="left">
                      BOSSLIFE100 INDEX
                    </Text.Body>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header;
